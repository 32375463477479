import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { db, auth } from "../config/firebase"; // Adjust the import according to your project structure
import {
  collection,
  query,
  getDocs,
  orderBy,
  limit,
  startAfter,
  where,
  Timestamp,
} from "firebase/firestore";

const LiveTransfers = () => {
  const [transfers, setTransfers] = useState([]);
  const [callBacks, setCallBacks] = useState([]);
  const [page, setPage] = useState(0);
  const [callBacksPage, setCallBacksPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageCallBacks, setRowsPerPageCallBacks] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);
  const [lastVisibleCallBacks, setLastVisibleCallBacks] = useState(null);
  const [orderLiveTransfers, setOrderLiveTransfers] = useState("asc");
  const [orderCallBacks, setOrderCallBacks] = useState("asc");
  const [orderByLiveTransfers, setOrderByLiveTransfers] =
    useState("time_stamp");
  const [orderByCallBacks, setOrderByCallbacks] = useState(
    "call_back_date_and_time"
  );
  const [cachedCallBacks, setCachedCallBacks] = useState({});
  const [cachedLiveTransfers, setCachedLiveTransfers] = useState({});
  const [user, setUser] = useState(null); // Assuming you have a way to get the current user

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = auth.currentUser;
      setUser(currentUser);
    };
    fetchUser();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!user) return;

  //     const agentRef = collection(db, "agents");
  //     const agentQuery = query(agentRef, where("uid", "==", user.uid));
  //     const agentSnapshot = await getDocs(agentQuery);

  //     if (agentSnapshot.empty) return;

  //     const agentDoc = agentSnapshot.docs[0];
  //     const agentId = agentDoc.id;

  //     const transfersRef = collection(db, `agents/${agentId}/live_transfers`);
  //     const q = query(
  //       transfersRef,
  //       orderBy(orderByLiveTransfers, orderLiveTransfers),
  //       limit(rowsPerPage)
  //     );
  //     const querySnapshot = await getDocs(q);

  //     const data = await Promise.all(
  //       querySnapshot.docs.map(async (doc) => {
  //         const data = doc.data();
  //         const callSid = data.call_sid;

  //         const transcribedCallRef = query(
  //           collection(db, "agents", agentId, "transcribed_calls"),
  //           where("call_sid", "==", callSid)
  //         );
  //         const transcribedCallDoc = await getDocs(transcribedCallRef);
  //         if (transcribedCallDoc.empty) {
  //           return {
  //             id: doc.id,
  //             ...doc.data(),
  //           };
  //         } else {
  //           const recordingUrl = !transcribedCallDoc.empty
  //             ? transcribedCallDoc.docs[0].data().recording_url
  //             : null;

  //           return {
  //             id: doc.id,
  //             recording_url: recordingUrl,
  //             ...doc.data(),
  //           };
  //         }
  //       })
  //     );

  //     setTransfers(data);
  //     setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
  //   };

  //   fetchData();
  // }, [rowsPerPage, orderLiveTransfers, orderByLiveTransfers]);

  const fetchCallBacks = useCallback(
    async (page) => {
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];
      const agentId = agentDoc.id;

      const callBacksRef = collection(db, `agents/${agentId}/lead_call_backs`);
      let q2;

      if (page > 0 && lastVisibleCallBacks) {
        q2 = query(
          callBacksRef,
          orderBy(orderByCallBacks, orderCallBacks),
          startAfter(lastVisibleCallBacks),
          limit(rowsPerPageCallBacks)
        );
      } else {
        q2 = query(
          callBacksRef,
          orderBy(orderByCallBacks, orderCallBacks),
          limit(rowsPerPageCallBacks)
        );
      }

      const querySnapshot2 = await getDocs(q2);
      const data2 = await Promise.all(
        querySnapshot2.docs.map(async (doc) => {
          let docData = doc.data();
          let leadId = docData.lead_id;

          // Get the lead data
          const leadRef = query(
            collection(db, "agents", agentId, "leads", leadId, "call_logs"),
            orderBy("time_stamp", "desc")
          );

          const leadDoc = await getDocs(leadRef);
          if (leadDoc.docs.length > 0) {
            const callLogData = leadDoc.docs[0].data();
            const callSid = callLogData.call_sid;

            // Get the recording URL
            const transcribedCallRef = query(
              collection(db, "agents", agentId, "transcribed_calls"),
              where("call_sid", "==", callSid)
            );
            const transcribedCallDoc = await getDocs(transcribedCallRef);
            const recordingUrl = !transcribedCallDoc.empty
              ? transcribedCallDoc.docs[0].data().recording_url
              : null;

            return {
              id: doc.id,
              recording_url: recordingUrl,
              ...doc.data(),
            };
          } else
            return {
              id: doc.id,
              ...doc.data(),
            };
        })
      );

      setCallBacks(data2);
      setLastVisibleCallBacks(
        querySnapshot2.docs[querySnapshot2.docs.length - 1]
      );

      // Cache the results
      setCachedCallBacks((prev) => ({
        ...prev,
        [page]: data2,
      }));
    },
    [
      user,
      orderByCallBacks,
      orderCallBacks,
      lastVisibleCallBacks,
      rowsPerPageCallBacks,
    ]
  );

  useEffect(() => {
    if (!cachedCallBacks[callBacksPage]) {
      fetchCallBacks(callBacksPage);
    } else {
      setCallBacks(cachedCallBacks[callBacksPage]);
    }
  }, [fetchCallBacks, callBacksPage, cachedCallBacks]);

  const fetchLiveTransfers = useCallback(
    async (_page) => {
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];
      const agentId = agentDoc.id;

      const liveTransfersRef = collection(
        db,
        `agents/${agentId}/live_transfers`
      );

      let q2 = query(
        liveTransfersRef,
        orderBy(orderByLiveTransfers, orderLiveTransfers),
        startAfter(lastVisible),
        limit(rowsPerPage)
      );

      if (_page > 0 && lastVisible) {
        q2 = query(
          liveTransfersRef,
          orderBy(orderByLiveTransfers, orderLiveTransfers),
          startAfter(lastVisible),
          limit(rowsPerPage)
        );
      } else {
        q2 = query(
          liveTransfersRef,
          orderBy(orderByLiveTransfers, orderLiveTransfers),
          limit(rowsPerPage)
        );
      }

      let docsQ2 = await getDocs(q2);

      if (docsQ2.empty) return;

      const liveTransfersData = [];

      await Promise.all(
        docsQ2.docs.map(async (doc) => {
          if (!doc.exists) return;
          const docData = doc.data();
          const leadPhoneNumber = docData.lead_phone_number;

          // Get the lead data
          const leadQuery = query(
            collection(db, "agents", agentId, "leads"),
            where("phone_number", "==", leadPhoneNumber),
            limit(1)
          );

          const leadDoc = await getDocs(leadQuery);

          // Get the latest call log from this lead
          const _callLogQuery = query(
            collection(
              db,
              "agents",
              agentId,
              "leads",
              leadDoc.docs[0].id,
              "call_logs"
            ),
            orderBy("time_stamp", "desc"),
            limit(1)
          );

          const callLogQuery = await getDocs(_callLogQuery);

          if (callLogQuery.docs.length > 0) {
            const callLogData = callLogQuery.docs[0].data();
            const callSid = callLogData.call_sid;

            // Get the recording URL
            const transcribedCallRef = query(
              collection(db, "agents", agentId, "transcribed_calls"),
              where("call_sid", "==", callSid)
            );
            const transcribedCallDoc = await getDocs(transcribedCallRef);
            const recordingUrl = !transcribedCallDoc.empty
              ? transcribedCallDoc.docs[0].data().recording_url
              : null;

            liveTransfersData.push({
              id: doc.id,
              recording_url: recordingUrl,
              ...doc.data(),
            });
          } else {
            liveTransfersData.push({
              id: doc.id,
              ...doc.data(),
            });
          }
        })
      );

      setTransfers(liveTransfersData);
      setLastVisible(docsQ2.docs[docsQ2.docs.length - 1]);

      // Cache the results
      setCachedLiveTransfers({
        ...cachedLiveTransfers,
        [page]: liveTransfersData,
      });
    },
    [user, orderByLiveTransfers, orderLiveTransfers, lastVisible, rowsPerPage]
  );

  useEffect(() => {
    if (!cachedLiveTransfers[page]) {
      fetchLiveTransfers(page);
    } else {
      setTransfers(cachedLiveTransfers[page]);
    }
  }, [fetchLiveTransfers, page, cachedLiveTransfers]);

  const handleChangeLiveTransferPage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeCallBackPage = async (event, newPage) => {
    setCallBacksPage(newPage);
  };

  // const handleChangeCallBackPage = async (event, newPage) => {
  //   setCallBacksPage(newPage);

  //   if (newPage > page) {
  //     const user = auth.currentUser;
  //     if (!user) return;

  //     const agentRef = collection(db, "agents");
  //     const agentQuery = query(agentRef, where("uid", "==", user.uid));
  //     const agentSnapshot = await getDocs(agentQuery);

  //     if (agentSnapshot.empty) return;

  //     const agentDoc = agentSnapshot.docs[0];
  //     const agentId = agentDoc.id;

  //     const callBacksRef = collection(db, `agents/${agentId}/lead_call_backs`);
  //     const q2 = query(
  //       callBacksRef,
  //       firestoreOrderBy(orderBy, order),
  //       startAfter(lastVisibleCallBacks),
  //       limit(rowsPerPage)
  //     );
  //     const querySnapshot2 = await getDocs(q2);
  //     const data2 = querySnapshot2.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     setCallBacks(data2);
  //     setLastVisibleCallBacks(
  //       querySnapshot2.docs[querySnapshot2.docs.length - 1]
  //     );
  //   }
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRowsPerPageCallBacks = (event) => {
    setRowsPerPageCallBacks(parseInt(event.target.value, 10));
    setCallBacksPage(0);
    setLastVisibleCallBacks(null); // Reset last visible document for new page size
  };

  const handleRequestSort = (property) => {
    const isAsc =
      orderByLiveTransfers === property && orderLiveTransfers === "asc";
    setOrderLiveTransfers(isAsc ? "desc" : "asc");
    setOrderByLiveTransfers(property);
  };

  const handleRequestSortCallBacks = (property) => {
    const isAsc = orderByCallBacks === property && orderCallBacks === "asc";
    setOrderCallBacks(isAsc ? "desc" : "asc");
    setOrderByCallbacks(property);
  };

  return (
    <div
      style={{
        margin: "25px",
      }}
    >
      <h1>Live transfers</h1>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "agent_phone_number"}
                    direction={
                      orderByLiveTransfers === "agent_phone_number"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("agent_phone_number")}
                  >
                    Agent Phone Number
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "call_sid"}
                    direction={
                      orderByLiveTransfers === "call_sid"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("call_sid")}
                  >
                    Call SID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "call_type"}
                    direction={
                      orderByLiveTransfers === "call_type"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("call_type")}
                  >
                    Call Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "lead_phone_number"}
                    direction={
                      orderByLiveTransfers === "lead_phone_number"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("lead_phone_number")}
                  >
                    Lead Phone Number
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "recording_url"}
                    direction={
                      orderByLiveTransfers === "recording_url"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("recording_url")}
                  >
                    Recording URL
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByLiveTransfers === "time_stamp"}
                    direction={
                      orderByLiveTransfers === "time_stamp"
                        ? orderLiveTransfers
                        : "asc"
                    }
                    onClick={() => handleRequestSort("time_stamp")}
                  >
                    Time Stamp
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transfers.map((transfer) => (
                <TableRow key={transfer.id}>
                  <TableCell>{transfer.agent_phone_number}</TableCell>
                  <TableCell>{transfer.call_sid}</TableCell>
                  <TableCell>{transfer.call_type}</TableCell>
                  <TableCell>{transfer.lead_phone_number}</TableCell>
                  <TableCell>
                    <a href={transfer.recording_url}>Open recording</a>
                  </TableCell>
                  <TableCell>
                    {new Date(
                      transfer.time_stamp.seconds * 1000
                    ).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={-1} // Unknown number of total rows
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={async (event, newPage) =>
            await handleChangeLiveTransferPage(event, newPage)
          }
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <br />
      <br />
      <h1>Scheduled call backs</h1>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "call_back_date_and_time"}
                    direction={
                      orderByCallBacks === "call_back_date_and_time"
                        ? orderCallBacks
                        : "asc"
                    }
                    onClick={() =>
                      handleRequestSortCallBacks("call_back_date_and_time")
                    }
                  >
                    Call back date and time
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "lead_first_name"}
                    direction={
                      orderByCallBacks === "lead_first_name"
                        ? orderCallBacks
                        : "asc"
                    }
                    onClick={() =>
                      handleRequestSortCallBacks("lead_first_name")
                    }
                  >
                    Lead first name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "raw_user_input"}
                    direction={
                      orderByCallBacks === "raw_user_input"
                        ? orderCallBacks
                        : "asc"
                    }
                    onClick={() => handleRequestSortCallBacks("raw_user_input")}
                  >
                    Call back transcription
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "recording_url"}
                    direction={
                      orderByCallBacks === "recording_url"
                        ? orderCallBacks
                        : "asc"
                    }
                    onClick={() => handleRequestSortCallBacks("recording_url")}
                  >
                    Recording URL
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderByCallBacks === "time_stamp"}
                    direction={
                      orderByCallBacks === "time_stamp" ? orderCallBacks : "asc"
                    }
                    onClick={() => handleRequestSortCallBacks("time_stamp")}
                  >
                    Time Stamp
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callBacks.map((callBack) => (
                <TableRow key={callBack.id}>
                  <TableCell>{callBack.call_back_date_and_time}</TableCell>
                  <TableCell>{callBack.lead_first_name}</TableCell>
                  <TableCell>{callBack.raw_user_input}</TableCell>
                  <TableCell>
                    <a href={callBack.recording_url}>
                      Open recording (if available)
                    </a>
                  </TableCell>
                  <TableCell>
                    {new Date(
                      new Timestamp(callBack.time_stamp).seconds * 1000
                    ).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={-1} // Unknown number of total rows
          rowsPerPage={rowsPerPageCallBacks}
          page={callBacksPage}
          onRowsPerPageChange={handleChangeRowsPerPageCallBacks}
          onPageChange={handleChangeCallBackPage}
        />
      </Paper>
    </div>
  );
};

export default LiveTransfers;
